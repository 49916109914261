<template>
  <footer>
    <div class="foot-nav-container pc_padding">
      <div class="footer-about">
        <div class="footer-item-title">
          About us
        </div>
        <div class="footer-item-content">
          Welcome to TimeinCities! Our mission is to provide accurate and up-to-date local times for
          cities around the world. With our easy-to-use interface, you can quickly find the current
          time in any city, customize your favorite clocks, and stay informed with automatic
          daylight saving updates. Perfect for global connections, business planning, and staying in
          touch with friends and family.
        </div>
      </div>
      <div class="footer-side-row">
        <div class="footer-side-resource">
          <div class="footer-item-title">
            Resources
          </div>
          <div class="footer-item-content resource">
            <div class="footer-item-content-item cursor" @click="goto('blogs')">
              Blogs
            </div>
            <div class="footer-item-content-item cursor" @click="goto('terms')">
              Terms of services
            </div>
            <div class="footer-item-content-item cursor" @click="goto('privacy')">
              Privacy policy
            </div>
          </div>
        </div>
        <div class="footer-side-contact">
          <div class="footer-item-title">
            Contact us
          </div>
          <div class="footer-item-content">
            <a class="footer-item-content-item"
              href="mailto:timeincities.support@gmail.com">timeincities.support@gmail.com</a>
          </div>
        </div>
      </div>
    </div>
    <div class="corner-module pc_padding">
      Copyright ©
      2024 TimeinCites. All Rights Reserved.
    </div>
  </footer>
</template>

<script>
import { mapGetters } from 'vuex'

export default {
  name: 'FootModule1',
  computed: {
    ...mapGetters({
      host: 'host',
      deviceType: 'deviceType',
    }),
  },
  methods: {
    goto (where) {
      console.log(this.$route.path);
      this.$router.push({ name: where });
    },
  },
};
</script>
